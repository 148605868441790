import React from 'react';
import css from './SectionWhyNew.module.css';
import line from '../../../assets/photos/lastLine.png';
import group from '../../../assets/photos/group.png';
import SectionImage from './SectionImage';
import { ExternalLink, NamedLink } from '../../../components';
import joinTeamImage from '../../../assets/photos/joinTeamText.png';
const SectionWhyNew = ({ currentUser }) => {
  return (
    <div className={css.root}>
      <h2 className={css.heading}>Reasons Creators Pick ukreate</h2>
      <div className={css.container}>
        <div className={css.bgLine}>
          <div className={css.absoluteContainer}>
            <SectionImage currentUser={currentUser} />
          </div>
        </div>
      </div>
      <div className={css.groupContainer}>
        <div className={css.group}>
          <img className={css.groupImage} src={group} alt="group" />
          <div className={css.groupText}>
            <h3 className={css.groupHeading}>Join the HQ Team at</h3>
            <p className={css.creatorLabel}>ukreate</p>
            <ExternalLink href="https://form.typeform.com/to/TZT2IBma" className={css.groupButton}>
              Work with us
            </ExternalLink>
          </div>
        </div>
      </div>
      {/* <div className={css.redDot}>.</div> */}
      <div className={css.orangeDot}>.</div>
      {/* <div className={css.redDotBig}>.</div> */}
      <div className={css.orangeDotRight}>.</div>
      {/* <div className={css.redDotRight}>.</div> */}
      <div className={css.redSmallLeft}>.</div>
      <div className={css.orangeDotTopRight}>.</div>
    </div>
  );
};

export default SectionWhyNew;

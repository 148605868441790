import React from 'react';
import css from './SectionHeroNew.module.css';
import heroImage from '../../../assets/photos/hero.jpeg';
import contact from '../../../assets/photos/person_book.png';
import subscription from '../../../assets/photos/subscriptions.png';
import { NamedLink } from '../../../components';
import { BsFilePersonFill } from 'react-icons/bs';
import { BsFillCollectionPlayFill } from 'react-icons/bs';
const SectionHeroNew = ({ currentUser }) => {
  const { attributes } = currentUser || {};
  const { profile } = attributes || {};
  const { publicData } = profile || {};
  const { userType } = publicData || {};
  const isBrand = userType === 'brand';
  return (
    <div className={css.root}>
      <div className={css.container}>
        <div className={css.hero}>
          <div className={css.textContainer}>
            <h1 className={css.title}>Connect, Create, and Grow on the Largest Kreator Platform</h1>
            {/* <p className={css.subtitle}>Best Creator Brands and Agencies</p> */}
            <NamedLink
              name={currentUser ? (isBrand ? 'SearchPage' : 'NewListingPage') : 'SignupPage'}
              className={css.heroButton}
              to={{ userType: 'creator' }}
            >
              Let’s Start Creating!
            </NamedLink>
          </div>
        </div>
      </div>
      <div className={css.heroImageContainer}>
        <div className={css.sectionImage}>
          <img src={heroImage} alt="hero" className={css.heroImage} />
          <NamedLink
            className={css.hireCreator}
            name={currentUser ? 'SearchPage' : 'SignupPage'}
            to={{ userType: 'brand' }}
          >
            {/* <img src={contact} alt="contact" className={css.contact} /> */}
            <BsFilePersonFill className={css.icon} />
            <p className={css.text}>Hire a creator</p>
          </NamedLink>
          <NamedLink
            name={currentUser ? (isBrand ? 'SearchPage' : 'NewListingPage') : 'SignupPage'}
            className={css.beCreator}
            //  name={'SearchPage'}
            to={{ userType: 'creator' }}
          >
            {/* <img src={subscription} alt="subscription" className={css.contact} /> */}
            <BsFillCollectionPlayFill className={css.icon} />
            <p className={css.text}>BE A CREATOR</p>
          </NamedLink>
        </div>
      </div>

      <div className={css.redDot}>.</div>
      <div className={css.orangeDot}>.</div>
      <div className={css.redDotBig}>.</div>
      <div className={css.orangeDotRight}>.</div>
      <div className={css.redDotRight}>.</div>
      <div className={css.redSmallLeft}>.</div>
    </div>
  );
};

export default SectionHeroNew;
